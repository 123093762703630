




























































































































import { Inject } from '@cds/common';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { WecomVideoServices } from '@/services/wecom-video-service';
import UserGroupSelector from '../userGroupSelector.vue';
import { PUBLISH_STATUS, CONDITION } from '../constants';
import { isEmpty, map, filter } from 'lodash';
import moment from 'moment';

let videoForm: Dict<any> = {};

const TYPE_FIELD_MAP: any = {
  share: 'shareWechatPic',
  moment: 'shareWechatPagePic',
  poster: 'poster'
}

export type UploadStatus = 'unupload' | 'uploading' | 'upload-success' | 'upload-fail'
type Status = 'unrelease' | 'release' | 'releasing' | 'offselves' | 'delete'
type Mode = 'create' | 'edit' | 'detail'
@Component({
  components: {
    UserGroupSelector
  },
})
export default class Video extends Vue {
  [x: string]: any;
  public form: any = {
    urlPath: '',
    sourceId: '',
    description: undefined,
    shareWechatPic: '',
    shareWechatPagePic: '',
    poster: '',
    target: 0,
    userType: 1,
    userIds: [],
    groups: [],
    topFlag: 0,
    effectTime: '',
    expirationTime: '',
    groupIds: [],
    customGroupIds: [],
    num: ''
  };
  public status: Status = 'unrelease'
  public uploadStatus: UploadStatus = 'unupload'
  public photoList: any[] = [];
  public unauthGroups: any[] = [];
  public progress: number = 0;
  public video: any = {};
  public publishStatus: any[] = PUBLISH_STATUS;
  public visible: Boolean = false
  public actionUrl: string = '';
  public rules: { [x: string]: any[] } = {
    sourceId: [{ required: true, message: '请选择频道' }],
    description: [{ required: true, message: '请输入' }],
    expirationTime: [{ required: true, message: '请选择下架日期' }],
    urlPath: [{ required: true, message: '请上传视频' }],
    shareWechatPic: [{ required: true, message: '请上传视频转发封面图' }],
    shareWechatPagePic: [{ required: true, message: '请上传朋友圈视频封面图' }],
    groups: [{
      validator: (rule: any, val: any[], callback: any) => {
        const form = videoForm;
        if (videoForm.groups.length === 0 && form.target === 1) {
          callback(new Error('请选择面向用户组'));
          return
        }
        callback()
      }
    }],
    num: [{
      validator: (rule: any, val: any, callback: any) => {
        const form = videoForm;
        if (val <= 0 && form.target === 1) {
          callback(new Error('请输入观看次数'));
          return
        }
        callback()
      }
    }]
  };
  public isOversize: boolean = false;
  public mode: Mode = 'create'
  public channelOptions: any[] = [];
  public conditionOptions: any[] = CONDITION;
  private fileList: any[] = [];
  public pickOptions: any = {
    disabledDate(time: any) {
      return time.getTime() < Date.now();
    }
  };

  public publishPickOptions: any = {
    disabledDate(time: any) {
      return time.getTime() < moment().startOf('days').valueOf();
    }
  }
  @Watch('form', { deep: true })
  public setForm(newVal: any) {
    videoForm = newVal;
  }
  @Inject(WecomVideoServices) private wecomVideoServices !: WecomVideoServices
  public getSourceOption() {
    this.wecomVideoServices.getAuthSourceOptions().then((res: any) => {
      this.channelOptions = res;
      if (!this.$route.params.videoId) {
        this.form.sourceId = res[0].key
      }
    })
  }
  public excced(file: any, fileList: any[]) {
    this.$message({
      type: 'warning',
      message: '视频文件最多上传一个，如需重新上传，请在下面删除当前视频文件'
    })
  }
  public handleChange() {
    this.fileList = [];
  }
  public handlePreview(file: any) {
    console.log(file);
    const { urlPath, name } = file;
    const loading = this.$loading({
      text: '下载中...'
    })
    const link = document.createElement('a');
    link.target = '_blank';
    link.style.display = 'none';
    link.href = urlPath;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    loading.close();
  }
  public handleRemove(file: any) {
    this.form.urlPath = '';
  }
  public handleGroupSubmit({ groupIds, customGroupIds }) {
    this.form.groupIds = groupIds;
    this.form.customGroupIds = customGroupIds;
  }
  public beforeUpload(files: any) {
    this.isOversize = false;
    // 文件类型进行判断
    const isVideo = files.type === "video/mp4";
    console.log('files', files);
    const isLt500M = files.size / 1024 / 1024 < 500;
    if (!isVideo) {
      this.$message.error('上传文件格式错误!');
      this.fileList = [];
      return false;
    } else {
      if (!isLt500M) {
        this.$message.error('上传文件大小不能超过 500MB!');
        this.isOversize = true;
        this.fileList = [];
        return false;
      } else {
      }
    }

    return isVideo && isLt500M;
  }
  public beforePhotoUpload(files: { type: string; size: number; name: string }) {
    // 文件类型进行判断

    const isJPG = files.type === 'image/jpeg';
    const isPNG = files.type === 'image/png';
    if (!isJPG && !isPNG) {
      this.$message.error('上传文件格式错误!');
      this.photoList = [];
      return false;
    }
    return isJPG || isPNG;
  }
  public customPhotoUpload(file: any, type: 'share' | 'poster' | 'moment' = 'share') {
    const formData = new FormData();
    formData.append('file', file.file);
    const loading = this.$loading({
      text: `上传中...`,
    });

    this.wecomVideoServices.posterUpload(formData).then((res) => {
      if (res) {
        this.form[TYPE_FIELD_MAP[type]] = res;
        return
      }
      this.$message({
        type: 'error',
        message: '上传失败'
      })
    }).catch((err) => {
      this.$message({
        type: 'error',
        message: '上传失败'
      })
    }).finally(() => {
      loading.close();
    });
  }
  public customUploadForPoster(file: any) {
    this.customPhotoUpload(file, 'poster')
  }
  public customUploadForShare(file: any) {
    this.customPhotoUpload(file, 'share')
  }
  public customUploadForMoment(file: any) {
    this.customPhotoUpload(file, 'moment')
  }
  public handleClose() {
    this.visible = false
  }
  public openDialog() {
    (this.$refs.videoForm as ElForm).validate(valid => {
      if (valid) {
        this.visible = true;

        if (moment(this.form.effectTime || Date.now()) > moment()) return;
        this.form.effectTime = moment().valueOf();
      }
    });
  }
  public publish() {
    const params = { ...this.form, saveType: 'release' };
    const { userIds, target, userType } = this.form;
    params.expirationTime = moment(params.expirationTime).startOf('days').valueOf();
    params.effectTime = moment(params.effectTime).startOf('days').valueOf();
    if (target === 0) {
      params.userIds = [];
      params.userGroupId = [];
    }
    if (target === 1 && userType !== 0) {
      params.userIds = [];
    }
    if (!isEmpty(userIds) && target === 1 && userType === 0) {
      params.userIds = map(userIds, 'employeeId');
    } else {
      params.userIds = [];
    }
    this.wecomVideoServices.videoRelease(params).then((res) => {
      if (res) {
        this.$message.success('操作成功');
        this.$router.go(-1);
        this.visible = false;
      }
    })
  }
  public getSharePhoto() {
    this.wecomVideoServices.getAllDefaultPhoto().then(res => {
      const { wechatShareDefaultImage, wechatSharePageImage } = res;
      if (res) {
        this.form.shareWechatPic = wechatShareDefaultImage;
        this.form.shareWechatPagePic = wechatSharePageImage
      }
    })
  }
  public customUpload(file: any) {
    const formData = new FormData();
    formData.append('file', file.file);
    const loading = this.$loading({
      text: `上传中...`,
    });
    this.uploadStatus = 'uploading';
    this.wecomVideoServices.videoUpload(formData, {
      onUploadProgress: (event: any) => {
        if (event.lengthComputable) {
          var progress = Math.round((event.loaded * 100) / event.total) > 99 ? 99 : Math.round((event.loaded * 100) / event.total);
          this.progress = progress;
        }
      }
    }).then((res) => {
      this.progress = 100;
      const { code, video } = res;
      if (code === 100200) {
        const { height, width, urlPath, fileName, videoTime, poster, videoDuration } = video;
        const postVideo = {
          height, width, urlPath, fileName, videoTime, poster, videoDuration
        };
        this.form = {
          ...this.form,
          ...postVideo
        }
        this.uploadStatus = 'upload-success';
        this.fileList = [{ ...postVideo, name: fileName }];
        (this.$refs.videoForm as ElForm).clearValidate('urlPath');
        return
      }
      this.$message({
        type: 'error',
        message: '上传视频失败'
      })
      this.uploadStatus = 'upload-fail'
    }).catch((err) => {
      console.log('error --> ', err);
      this.uploadStatus = 'upload-fail'
      this.$message({
        type: 'error',
        message: '上传视频失败'
      })
    }).finally(() => {
      loading.close();
      this.progress = 0;
    });
  }
  public async cancel() {
    try {
      await this.$confirm('是否放弃保存当前页信息？', '提示', {
        confirmButtonText: '取消',
        cancelButtonText: '确定',
        closeOnClickModal: false,
        showClose: false,
        type: 'warning'
      });
    } catch (e) {
      this.$router.go(-1);
    }
  }
  public async save() {
    (this.$refs.videoForm as ElForm).validate(valid => {
      if (valid) {
        const params: Dict<any> = { ...this.form, saveType: 'save' }
        const { userIds, target, userType } = this.form;
        params.expirationTime = moment(params.expirationTime).startOf('days').valueOf();
        if (target === 0) {
          params.userIds = [];
          params.userGroupId = [];
        }
        if (target === 1 && userType !== 0) {
          params.userIds = [];
        }
        if (!isEmpty(userIds) && target === 1 && userType === 0) {
          params.userIds = map(userIds, 'employeeId');
        } else {
          params.userIds = [];
        }
        if (params.effectTime) {
          params.effectTime = moment(params.effectTime).startOf('days').valueOf();
        }
        this.wecomVideoServices.videoRelease(params).then(res => {
          if (!res) return;
          this.$message.success('操作成功');
          this.$router.go(-1);
        })
      }
    });
  }
  public async beforeRemove(file: any, fileList: any) {
    try {
      if (this.isOversize) return
      const res = await this.$confirm('确定要移除当前视频文件吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      this.$message({
        message: '已移除',
        type: 'success'
      });
      return res;
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消操作'
      });
      return Promise.reject(e)
    }
  }
  public async getDeatil() {
    // getEidtInfo here
    const videoId = this.$route.params.videoId;
    if (!videoId) {
      return;
    }
    this.wecomVideoServices.videoDetail(videoId).then((res) => {
      if (res) {
        const { id, topFlag, status, urlPath, fileName, num } = res;
        if (res.topTime) {
          res.topTime = moment(res.topTime).format('YYYY-MM-DD HH:mm:ss')
        }
        this.status = status;
        this.video = res;
        const groupIds = []
        const customGroupIds = []
        const groups = map(res.groups, (group) => {
          if (group.groupType === 'custom') {
            customGroupIds.push(group.groupId);
          } else {
            groupIds.push(group.groupId)
          }
          return group.groupType === 'custom' ? `custom_${group.groupId}` : `${group.groupId}`;
        })
        this.form = {
          ...this.form,
          ...res,
          id,
          topFlag,
          num,
          userType: 1,
          groups,
          groupIds,
          customGroupIds
        }
        
        this.unauthGroups = map(filter(res.groups, (item) => !item.editable), (item) => {
          const group = { ...item, disabled: true, label: item.groupName };
          if (item.groupType === 'custom') {
            group.key = `custom_${item.groupId}`;
            group.isCustomer = 1;
            group.label = `${item.groupName}（自定义分组）`
          } else {
            group.key = `${item.groupId}`;
            group.isCustomer = 0;
          }
          return group
        })
        this.fileList = [{
          name: fileName || 'xxxx.mp4',
          urlPath
        }]
      }
    })

  }
  public async created(): Promise<void> {
    this.getDeatil();
    this.getSourceOption();
    console.log(this.$route);
    if (this.$route.name === 'videoEdit') {
      this.mode = 'edit'
    } else if (this.$route.name === 'videoDetail') {
      this.mode = 'detail'
    } else {
      this.mode = 'create';
    }
    if (this.mode === 'create') {
      this.getSharePhoto();
    }
  }

  private mounted() {
  }
}
